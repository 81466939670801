import React from 'react';

import {
    overwrite,
    section as sectionClass,
    grid,
    title as titleClass,
    description as descriptionClass,
    subtitle as subtitleClass,
    button as buttonClass,
} from './plain.module.scss';
import { ISection, ISectionContentButton } from '../../models/section.model';

import Section from '../hoc/section';

interface IPlainProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function Plain({ className, TitleTag, section }: IPlainProps) {
    const { sectionId, content, css, style } = section;
    const { texts, buttons } = content;
    const [title, subtitle, description] = texts;
    const button: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className} ${overwrite}`}
            classes={{
                container: grid,
                title: titleClass,
                description: descriptionClass,
                subtitle: subtitleClass,
                button: buttonClass,
            }}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            buttonText={button.text}
            buttonUrl={button.url}
            css={css}
            style={style}
        />
    );
}
