// extracted by mini-css-extract-plugin
export var authorBox = "video-card-module--author-box--6246c";
export var categoryText = "video-card-module--category-text--c4800";
export var dateText = "video-card-module--date-text--376d9";
export var image = "video-card-module--image--dccd4";
export var imageBox = "video-card-module--image-box--7a05e";
export var paragraph = "video-card-module--paragraph--d1a39";
export var ratio = "video-card-module--ratio--31c28";
export var redirectButton = "video-card-module--redirect-button--2910f";
export var tagItem = "video-card-module--tag-item--de48e";
export var tagList = "video-card-module--tag-list--a9d07";
export var tagText = "video-card-module--tag-text--45810";
export var titleText = "video-card-module--title-text--c3d57";
export var wrapper = "video-card-module--wrapper--26c80";