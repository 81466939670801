import React, { ComponentProps } from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    button,
    desktopImage,
    ratioClass,
    mobileImage,
    ratioClassMobile,
    contentContainer,
    offsetContainer,
    contentWrapper,
    header,
    wrapper,
    subHeader,
} from './banner.module.scss';
import IBanner from '../../models/banner.model';
import { relations } from '../../config/relations';

import Button from '../atoms/button';
import { useIsLinkInternal } from '../../hooks/use-is-link-internal';

interface IBannerProps {
    className?: string;
    offsetClassName?: string;
    banner: IBanner;
    TitleTag?: React.ElementType;
    loading?: ComponentProps<typeof Image>['loading'];
}

const Banner: React.FC<IBannerProps> = ({
    className = '',
    offsetClassName = '',
    banner,
    TitleTag = 'h2',
    loading,
}) => {
    const isLinkInternal = useIsLinkInternal(banner.buttonUrl);

    return (
        <div className={`${wrapper} ${className}`} key={`${banner.bannerId}`} style={banner.style}>
            <Image
                className={desktopImage}
                ratioClass={ratioClass}
                media={banner.media}
                relation={relations.mainImage}
                loading={loading}
            />
            <Image
                className={mobileImage}
                ratioClass={ratioClassMobile}
                media={banner.media}
                relation={relations.mainImageMobile}
                loading={loading}
            />
            <div className={contentContainer}>
                <div className={`${offsetContainer} ${offsetClassName}`}>
                    <div className={contentWrapper}>
                        <TitleTag className={header}>{banner.title}</TitleTag>
                        <span className={subHeader}>{banner.subtitle}</span>
                        {banner.buttonUrl ? (
                            isLinkInternal ? (
                                <Button
                                    as={'link'}
                                    to={banner.buttonUrl}
                                    className={button}
                                    stylePreset={'main'}
                                >
                                    {banner.buttonText}
                                </Button>
                            ) : (
                                <Button
                                    as={'externalLink'}
                                    href={banner.buttonUrl}
                                    className={button}
                                    stylePreset={'main'}
                                >
                                    {banner.buttonText}
                                </Button>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
