import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    wrapper,
    dateText,
    categoryText,
    titleText,
    paragraph,
    imageBox,
    image,
    ratio,
    redirectButton,
    tagList,
    tagItem,
    tagText,
    authorBox,
} from './video-card.module.scss';

import { IVideoCard } from '../../models/video.model';
import useTranslations from '../../hooks/use-translations';

import Button from '../atoms/button';
import Avatar from '../atoms/avatar';
import {relations} from "../../config/relations";

interface IVideoCardProps {
    className?: string;
    videoCard: IVideoCard;
    TitleTag?: React.ElementType;
}

const VideoCard: React.FC<IVideoCardProps> = ({ className = '', videoCard, TitleTag = 'h2' }) => {
    const { media, pathname, mainCategory, title, lead, displayPublishedAt, tags, author } =
        videoCard;
    const t = useTranslations('VideoCard');

    return (
        <Link to={pathname} className={`${wrapper} ${className} video-card`}>
            <div className={`${imageBox} video-card__image-box`}>
                <Image className={`${image} video-card__image`} media={media} ratioClass={ratio} relation={relations.thumbnailImage}/>
            </div>
            <time className={`${dateText} video-card__date`}>{displayPublishedAt}</time>
            <TitleTag className={`${titleText} video-card__title`}>{title}</TitleTag>
            {author && (
                <div className={`${authorBox} video-card__author`}>
                    <Avatar className="video-card__avatar" media={author.media} />
                    <p className="video-card__author-name">{author.displayName}</p>
                </div>
            )}
            {mainCategory && (
                <p className={`${categoryText} video-card__category-name`}>{mainCategory.title}</p>
            )}
            {lead && <p className={`${paragraph} video-card__lead`}>{lead}</p>}
            {tags && tags.length > 0 && (
                <ul className={`${tagList} video-card__tag-list`}>
                    {tags.map((tag) => {
                        return (
                            <li
                                key={`tag-item-${tag.tagId}`}
                                className={`${tagItem} video-card__tag-item`}
                            >
                                <p className={`${tagText} video-card__tag-name`}>{tag.name}</p>
                            </li>
                        );
                    })}
                </ul>
            )}
            <Button
                className={`${redirectButton} video-card__button`}
                context="postCard"
                as="element"
            >
                {t.readmore}
            </Button>
        </Link>
    );
};

export default VideoCard;
