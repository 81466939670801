// extracted by mini-css-extract-plugin
export var button = "banner-module--button--5bbdd";
export var contentContainer = "banner-module--content-container--205df";
export var contentWrapper = "banner-module--content-wrapper--799a7";
export var desktopImage = "banner-module--desktop-image--dc1c9";
export var header = "banner-module--header--b3a35";
export var mobileImage = "banner-module--mobile-image--1e467";
export var offsetContainer = "banner-module--offset-container--eb530";
export var ratioClass = "banner-module--ratio-class--489a5";
export var ratioClassMobile = "banner-module--ratio-class-mobile--85222";
export var subHeader = "banner-module--sub-header--243ad";
export var wrapper = "banner-module--wrapper--179d2";